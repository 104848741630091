import { navigate as navigateFunc } from './functions';
import { getConfig, getBasePath } from './functions';
import { open_browser_web, renameObjectKeys } from 'utils/validators';
import { storageService } from './validators';
import eventManager from './eventManager';
import { EVENT_MANAGER_CONSTANTS, landingEntryPoints } from './constants';
import isEmpty from "lodash/isEmpty";
import { MY_ACCOUNT_ROUTES } from '../routeConstants/MyAccount';

export const nativeCallback = async ({ action = null, message = null, events = null, action_path = null, rnData = {} } = {}) => {
  let newAction = null;
  let callbackData = {};
  const config = getConfig();
  let project = config.project;

  let oldToNewMethodsMapper = {
    'open_pdf': 'open_url',
    'take_control_reset_hard': 'reset_back_button_control',
    'take_control_reset': 'reset_back_button_control',
    'take_control': 'take_back_button_control',
    'open_in_browser': 'open_browser',
    'exit': project === 'insurance' ? 'exit_module' : 'exit_web',
    'native_back': project === 'insurance' ? 'exit_module' : 'exit_web',
    'native_reset': 'restart_module',
    'events': 'event',
    'resume_provider': 'resume_payment',
    'show_quotes': 'native_back',
    'exit_web_sdk': 'exit_web'
  }

  if (!action && !events) {
    return;
  }
  
  if (action) {
    newAction = oldToNewMethodsMapper[action];
    callbackData.action = newAction || action;
  }

  if(!isEmpty(rnData)) {
    callbackData.rnData = rnData;
  }

  if(events && events.properties) {
    console.log(events.properties);
  }

  if (action === 'login_required') {
    if (config.Web) {
      storageService().clear();
      if (config.isIframe) {
        let message = JSON.stringify({
          type: "iframe_close",
        });
        window.callbackWeb.sendEvent(message);
        return;
      }
      
      let path = ['iw-dashboard', 'w-report'].includes(config.project) ? `/${config.project}/login` : '/login';
      eventManager.emit(EVENT_MANAGER_CONSTANTS.redirectPath, path);
    } else {
      nativeCallback({ action: "session_expired" });
    }
    return;
  }

  if (action === 'native_back' || action === 'exit') {
    if (config.isNative) callbackData.action = 'exit_web';
    else window.location.href = redirectToPath('/');
  }

  if (action === 'open_pdf') {
    if (config.Android) {
      message.url = "https://docs.google.com/gview?embedded=true&url=" + message.url;
    }
  }

  if (action === 'open_inapp_tab') {
    if (config.Web) {
      open_browser_web(message.url, '')
    } else {
      let url = 'https://fis.do/m/module?action_type=native';
      if (config.productName === 'finity') {
        url = 'https://w-ay.in/m/module?action_type=native';
      }

      url += '&native_module=' + encodeURIComponent('app/open_inapp_tab');
      url += '&url=' + encodeURIComponent(message.url);

      if(message.back_url) {
        url += '&back_redirection_url=' + message.back_url;
      }

      nativeCallback({
        action: 'open_module', message: {
          action_url: url
        }
      });

      return;
    }
  }

  if (events && window.clevertap) {
    if (events.properties) {
      window.clevertap.event.push(events.event_name, events.properties);
    } else {
      window.clevertap.event.push(events.event_name);
    }
  }

  if (config.generic_callback) {
    if (action === 'take_control_reset_hard' || action === 'take_control_reset') {
      nativeCallback({ action: 'hide_top_bar' });
    }

    if (action === 'take_control') {
      let keysMap = {
        'back_url': 'url',
        'show_top_bar': 'show_back_top_bar',
        'top_bar_title': 'title',
        'back_text': 'message'
      };
      message = renameObjectKeys(message, keysMap);
    }

    if (action === 'resume_provider') {
      nativeCallback({ action: 'show_top_bar', message: { title: message.provider } });
      callbackData.action = 'open_url';
      message = {
        url: message.resume_link
      }
    }

    if (message) {
      callbackData.action_data = message;
    }

  } else {

    if (message) {
      callbackData.data = message;
    }

    if ( project === 'insurance') {
      let notInInsuranceV2 = ['take_control', 'take_control_reset'];
      if (notInInsuranceV2.indexOf(action) !== -1) {
        return;
      }
    }
  }

  if (config.app !== 'web') {
    let pathname = window.location?.pathname || ""
    if(pathname.indexOf('appl/web') !== -1) {
      pathname = pathname.split("/")[5] || "/";
    }
    
    const entryPath = storageService().get('entry_path');

    const isExitScreen = ["/", "/mf", "/landing", "/landing/bank-list", "/invest/explore"].includes(pathname);
    if (
      config.isSdk &&
      !isExitScreen &&
      (entryPath !== pathname) &&
      (callbackData.action === 'exit_web' || callbackData.action === 'exit_module' || callbackData.action === 'open_module')
    ) {
        window.location.href = redirectToPath('/');
    } else {
      if (config.app === 'android') {
        window.Android.callbackNative(JSON.stringify(callbackData));
      }

      if (config.app === 'ios') {
        window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
      }
    }
  } else {
    if (action === 'open_in_browser' || action === 'open_url') {
      open_browser_web(message.url, '_blank')
    } else if (action === 'resume_provider') {
      open_browser_web(message.resume_link, '_self')
    } else if (action === '2fa_expired') {
      storageService().remove('currentUser');
      storageService().setBoolean('session-timeout', true);
      eventManager.emit(EVENT_MANAGER_CONSTANTS.redirectPath, "/login/verify-pin");
    } else {
      return;
    }
  }
};


export function openNativeModule(moduleName) {
  let url = 'https://fis.do/m/module?action_type=native';
  if (getConfig().productName === 'finity') {
    url = 'https://w-ay.in/m/module?action_type=native';
  }

  url += '&native_module=' + encodeURIComponent(moduleName);
  nativeCallback({
    action: 'open_module', message: {
      action_url: url
    }
  });
}

export function openModule(moduleName, props, additionalParams = {}) {
  const config = getConfig();
  if (config.isWebOrSdk) {
    const module_mapper = {
      'app/portfolio': '/reports',
      'app/profile': '/my-account',
      'invest/save_tax': '/invest',
      'invest/nps': '/nps/info',
      'account/setup_2fa': MY_ACCOUNT_ROUTES.SET_PIN(additionalParams?.routeUrlParams || '')
    }
    
    let moduleNameWeb = module_mapper[moduleName] || '/';
    if(props) {
      const navigate = navigateFunc.bind(props);
      navigate(moduleNameWeb)
    } else {
      let module_url = `${getBasePath()}${moduleNameWeb}${config.searchParams}`;
      window.location.href = module_url;
    }

  } else {
    openNativeModule(moduleName);
  }
}

export function openPdfCall(data = {}, useOpenPdfAction = false) {
  let url = data.url || '';
  if (!url) {
    return;
  }

  let current_url = window.location.href;

  if (!data.back_url) {
    data.back_url = current_url;
  }

  if (getConfig().isWebOrSdk && !useOpenPdfAction) {
      nativeCallback({
          action: 'open_in_browser',
          message: {
              url: url
          }
      });
  } else {

    nativeCallback({
      action: 'take_control', message: {
        back_url: data.back_url,
        show_top_bar: false
      },

    });

    nativeCallback({
      action: 'show_top_bar', message: {
        title: data.header_title, icon: data.icon || 'close'
      }
    });

    nativeCallback({ action: 'open_pdf', message: { url: url } });
  }

}

export function redirectToPath(path) {
  return `${getBasePath()}${path}${getConfig().searchParams}`;
}

export function handleNativeExit(props, data) {
  const config = getConfig();
  const navigate = navigateFunc.bind(props);
  const nativeExitActions = ["native_back", "exit"];
  const sdkExitActions = ["exit_web", "exit_module", "open_module"];
  const entryPath = storageService().get('entry_path');
  if (
    (nativeExitActions.includes(data.action) && !config.isNative) ||
    (config.isSdk &&
      props.location?.pathname !== "/" && (entryPath !== props.location.pathname) &&
      sdkExitActions.includes(data.action))
  ) {
    const searchParams = `base_url=${config.base_url}&partner_code=${config.code}`;
    if(storageService().get("flow-type") === "notification") {
      storageService().remove("flow-type");
      navigate("/notification", {
        searchParams: searchParams
      });
    } else {
      const fromState = props.location?.state?.fromState;
      const pathname = "/";
      if (landingEntryPoints.includes(fromState)) {
        props.history.goBack();
      } else {
        navigate(pathname, {
          searchParams: searchParams,
        });
      }
    }
  } else {
    nativeCallback(data);
  }
}