import {
  AUTH_VERIFICATION_DATA,
  BOTTOMSHEET_KEYS,
  CAMPAIGN_NAME_MAPPER,
  EVENT_STATUS_MAPPER,
  INVESTMENT_OPTIONS,
  RESTRICTED_FEATURES,
  WEBAPP_LANDING_PATHNAME_MAPPER,
} from "../../constants/webappLanding";
import {
  getBasePath,
  getConfig,
  getPartnerName,
  isTradingEnabled,
} from "../../utils/functions";
import { getPartnerData } from "../../utils/partnerConfigs";
import {
  splitMobileNumberFromContryCode,
  storageService,
} from "../../utils/validators";
import { get, isEmpty, isFunction } from "lodash-es";
import { nativeCallback } from "../../utils/native_callback";
import {
  kycStatusMapper,
  premiumBottomSheetMapper,
} from "../../dashboard/Invest/constants";
import { getAccountSummary } from "businesslogic/apis/common";
import eventManager from "../../utils/eventManager";
import { EVENT_MANAGER_CONSTANTS } from "../../utils/constants";
import { getCampaignData } from "businesslogic/utils/app/functions";
import {
  getNPSInvestmentStatus,
  hitCampaignFeedbackUrl,
} from "businesslogic/apis/app";
import store from "../../dataLayer/store";
import {
  setBankList,
  setCampaign,
  setKyc,
  setNps,
  setPartner,
  setReferral,
  setSubscriptionStatus,
  setUser,
  updateAppStorage,
} from "businesslogic/dataStore/reducers/app";
import Api from "../../utils/api";
import { FREEDOM_PLAN_STORAGE_CONSTANTS } from "../../freedom_plan/common/constants";
import { isEquityCompleted } from "../../kyc/common/functions";
import { isReadyToInvest } from "../../kyc/services";
import { isDematAccountDisabled, isDematAccountRekycInprogress } from "../kyc/functions";
import { PATHNAME_MAPPER as KYC_PATHNAME_MAPPER } from "../../kyc/constants";
import { buildUrlWithQueryParams } from "businesslogic/utils/common/functions";

/* eslint-disable */
export const initData = async () => {
  const currentUser = storageService().get("currentUser");
  const user = storageService().getObject("user");
  const kyc = storageService().getObject("kyc");
  if (currentUser && !isEmpty(user) && !isEmpty(kyc)) {
    const referral = storageService().getObject("referral");
    if (isEmpty(referral)) {
      const queryParams = {
        campaign: ["user_campaign"],
        nps: ["nps_user"],
        bank_list: ["bank_list"],
        referral: ["subbroker", "p2p"],
        equity: ["subscription_status"],
      };
      const result = await getAccountSummary(Api, queryParams);
      storageService().set("dataSettedInsideBoot", true);
      setSDKSummaryData(result);
      store.dispatch(
        updateAppStorage({
          dataSettedInsideBoot: true,
        })
      );
    }
  } else {
    const result = await getAccountSummary(Api);
    storageService().set("dataSettedInsideBoot", true);
    setSummaryData(result);
    store.dispatch(
      updateAppStorage({
        dataSettedInsideBoot: true,
      })
    );
  }
};

export const setSummaryData = (result, skipStoreUpdate = false) => {
  const currentUser = result?.data?.user?.user?.data || {};
  const userKyc = result?.data?.kyc?.kyc?.data || {};
  const subscriptionStatus =
    result?.data?.equity?.subscription_status?.data || {};
  storageService().set("currentUser", true);
  storageService().setObject("user", currentUser);
  storageService().setObject("kyc", userKyc);

  const campaignData = getCampaignData(result.data.campaign.user_campaign.data);
  const referral = result?.data?.referral || {};
  const bankList = result?.data?.bank_list?.bank_list?.data || [];
  const nps = result?.data?.nps?.nps_user?.data;
  storageService().setObject("campaign", campaignData);
  storageService().setObject("npsUser", nps);
  storageService().setObject("banklist", bankList);
  storageService().setObject("referral", referral);
  if (!isEmpty(subscriptionStatus)) {
    storageService().setObject(
      FREEDOM_PLAN_STORAGE_CONSTANTS.subscriptionStatus,
      subscriptionStatus
    );
  }
  let partner = "";
  let consent_required = false;
  if (result.data.partner.partner.data) {
    partner = result.data.partner.partner.data.name;
    consent_required = result.data.partner.partner.data.consent_required;
  }
  storageService().set("consent_required", consent_required);
  const subbrokerCode = result.data.referral.subbroker.data.subbroker_code;
  const subBrokerCodePartersList = [
    "hbl",
    "sbm",
    "flexi",
    "medlife",
    "life99",
    "taxwin",
    "ippb",
    "quesscorp",
    "sahaj",
    "mspl",
  ];
  partner = getPartnerName(partner);
  if (subBrokerCodePartersList.includes(subbrokerCode)) {
    partner = subbrokerCode;
  }
  storageService().set("partner", partner);
  if (!skipStoreUpdate) {
    store.dispatch(setKyc(userKyc));
    store.dispatch(setUser(currentUser));
    store.dispatch(setCampaign(campaignData));
    store.dispatch(setNps(nps));
    store.dispatch(setReferral(referral));
    store.dispatch(setBankList(bankList));
    store.dispatch(setSubscriptionStatus(subscriptionStatus));
  }
  store.dispatch(setPartner(partner));
  eventManager.emit(EVENT_MANAGER_CONSTANTS.updateAppTheme);
  setNpsData(result);
};

const setSDKSummaryData = (result) => {
  const subscriptionStatus =
    result?.data?.equity?.subscription_status?.data || {};
  if (!isEmpty(subscriptionStatus)) {
    storageService().setObject(
      FREEDOM_PLAN_STORAGE_CONSTANTS.subscriptionStatus,
      subscriptionStatus
    );
  }
  const campaignData = getCampaignData(
    result?.data?.campaign?.user_campaign?.data
  );
  const nps = result?.data?.nps?.nps_user?.data || {};
  const bankList = result?.data?.bank_list?.bank_list?.data || [];
  const referral = result?.data?.referral || {};
  storageService().setObject("campaign", campaignData);
  storageService().setObject("npsUser", nps);
  storageService().setObject("banklist", bankList);
  storageService().setObject("referral", referral);
  store.dispatch(setCampaign(campaignData));
  store.dispatch(setNps(nps));
  store.dispatch(setReferral(referral));
  store.dispatch(setBankList(bankList));
  store.dispatch(setSubscriptionStatus(subscriptionStatus));
  setNpsData(result);
};

const setNpsData = async (result) => {
  const npsInvestment = result?.data?.user?.user?.data?.nps_investment;
  const isDocRequired = result?.data?.nps?.nps_user?.data?.is_doc_required;
  if (npsInvestment && isDocRequired) {
    try {
      const data = await getNPSInvestmentStatus(Api);
      storageService().setObject(
        "nps_additional_details",
        data.registration_details
      );
      storageService().setObject("nps_data", data);
      if (!data?.registration_details?.additional_details_status) {
        storageService().set("nps_additional_details_required", true);
      } else {
        storageService().set("nps_additional_details_required", false);
      }
    } catch (err) {
      console.log("err ", err);
    }
  } else {
    storageService().set("nps_additional_details_required", false);
  }
};

export const getInvestCardsData = (
  investSections = [],
  feature,
  fallbackOptions,
  maximumProducts
) => {
  const config = getConfig();
  let isMfOnly = false,
    showPortfolioOverview = false;
  let data = getEnabledFeaturesData(config, investSections, feature);
  const user = get(store.getState(), "app.user", {});

  const FEATURES_TO_ENABLE_PORTFOLIO = ["mf", "taxFiling"];
  if (
    user.active_investment &&
    data.cardsData.length <= FEATURES_TO_ENABLE_PORTFOLIO.length
  ) {
    showPortfolioOverview = true;
    data.cardsData.forEach((el) => {
      if (!FEATURES_TO_ENABLE_PORTFOLIO.includes(el.id)) {
        showPortfolioOverview = false;
      }
    });
  }
  if (data.cardsData.length === 1) {
    isMfOnly = true;
    if (!isEmpty(fallbackOptions)) {
      data = getEnabledFeaturesData(config, fallbackOptions, feature);
    }
  }
  
  let { cardsData = [], featureIndex } = data;
  if (featureIndex !== -1) {
    const selectedCardData = cardsData.find((el) => el.id === feature);
    cardsData = cardsData.filter((el) => el.id !== feature);
    if (!isEmpty(selectedCardData)) {
      cardsData.unshift(selectedCardData);
    }
    data.cardsData = cardsData;
  }
  
  if (maximumProducts && data.cardsData.length > maximumProducts) {
    data.cardsData.splice(
      maximumProducts - 1,
      4,
      INVESTMENT_OPTIONS.categoryViewAll
    );
  }

  return {
    investCardsData: data.cardsData,
    isMfOnly,
    showPortfolioOverview,
    enabledFeatures: data.enabledFeatures,
  };
};

export const getEnabledFeaturesData = (config, investOptions, feature) => {
  const { productName, features = {} } = config;
  const state = store.getState();
  const referralData = get(state, "app.referral", {});
  const kyc = get(state, "app.kyc", {});
  let subbrokerCode = "";
  let subbrokerFeatures = {};
  if (referralData?.subbroker?.data) {
    subbrokerCode = referralData.subbroker.data.subbroker_code;
    if (["fisdom", "finity"].includes(subbrokerCode)) {
      subbrokerCode = "";
    } else {
      const subbrokerData = getPartnerData(productName, subbrokerCode);
      subbrokerFeatures = subbrokerData.features || {};
    }
  }

  let cardsData = [],
    featureIndex = -1;

  investOptions.forEach((section, index) => {
    const isRestrictedFeature = RESTRICTED_FEATURES.includes(section);
    const isFeatureNotEnabled = (subbrokerCode && !subbrokerFeatures[section]) || !features[section];
    const isRestrictedFeatureNotEnabled = isRestrictedFeature && isFeatureNotEnabled;
    const isStocksAndIpoNotEnabled = ["stocks", "ipo"].includes(section) && !isTradingEnabled(kyc);
    if (isRestrictedFeatureNotEnabled) {
      return;
    } else if (isStocksAndIpoNotEnabled) {
      return;
    } else {
      const cardData = INVESTMENT_OPTIONS[section];
      if (!isEmpty(cardData)) {
        cardsData.push(cardData);
        if (feature === section) {
          featureIndex = index;
        }
      }
    }
  });
  const enabledFeatures = !isEmpty(subbrokerCode)
    ? subbrokerFeatures
    : features;
  return { cardsData, featureIndex, enabledFeatures };
};

export const getInvestSections = (config) => {
  if (isEmpty(config)) {
    config = getConfig();
  }
  const investSections =
    config.code === "taxwin" ? config.mfSections : config.featuresList;
  return investSections;
};

export const isMfOnlyEnabled = () => {
  const config = getConfig();
  const investSections = getInvestSections(config);
  const { cardsData } = getEnabledFeaturesData(config, investSections);
  return cardsData.length === 1;
};

export const getEnabledMarketingBanners = (banners = [], enabledFeatures) => {
  const availableBanners =  banners.filter(
    (data) =>
      dateValidation(data.endDate, data.startDate) &&
      validateFeature(data.id, enabledFeatures)
  );
  const marketingBanners = availableBanners.slice(0, 6);
  return marketingBanners;
};

export const dateValidation = (endDate, startDate) => {
  if (!endDate && !startDate) return true;
  const date = new Date();
  const currentDate =
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  const startDateInMs = Date.parse(startDate);
  const endDateInMs = Date.parse(endDate);
  const currentDateInMs = Date.parse(currentDate);
  const isValidStartDate = startDate && startDateInMs <= currentDateInMs;
  const isValidEndDate = endDate && currentDateInMs <= endDateInMs;
  const isValidDateRange = startDateInMs <= endDateInMs;
  if (isValidStartDate && isValidEndDate && isValidDateRange) {
    return true;
  }
  if (!endDate && isValidStartDate) {
    return true;
  }
  if (!startDate && isValidEndDate) {
    return true;
  }
  return false;
};

export const validateFeature = (type, enabledFeatures = {}) => {
  if (RESTRICTED_FEATURES.includes(type) && !enabledFeatures[type]) {
    return false;
  }
  const state = store.getState();
  const kyc = get(state, "app.kyc", {});
  const user = get(state, "app.user", {});
  if (["ipo", "stocks"].includes(type)) {
    return isTradingEnabled(kyc) && isEquityCompleted() && !isDematAccountDisabled(kyc);
  } else if (type === "freedomplan") {
    return isFreedomPlanEntryEnabled();
  } else if (type === "equityKyc") {
    return isTradingEnabled() && !isEquityCompleted();
  } else if (["stocksBanner", "ipoBanner"].includes(type)) {
    return isTradingEnabled(kyc);
  } else if (type === "refer") {
    return isShareReferralEnabled(user);
  }
  return true;
};

export const isShareReferralEnabled = (user = {}, baseConfig = {}) => {
  if (isEmpty(baseConfig)) {
    baseConfig = getConfig();
  }
  return user?.referral_campaign_status === "active" && baseConfig?.referralConfig?.shareRefferal;
};

export const isFreedomPlanEntryEnabled = () => {
  const state = store.getState();
  const subscription = get(state, "app.subscriptionStatus", {});
  const showFreedomPlan = subscription?.freedom_cta || subscription?.renewal_cta;
  const freedomPlanEntryEnabled = isFreedomPlanEnabled(subscription) && showFreedomPlan;
  return freedomPlanEntryEnabled;
};

export const isFreedomPlanEnabled = (subscriptionData = {}) => {
  const state = store.getState();
  if (isEmpty(subscriptionData)) {
    subscriptionData = get(state, "app.subscriptionStatus", {});
  }
  const kyc = get(state, "app.kyc", {});
  const isFreedomPlanBlocked = subscriptionData.subscription_status === "BLOCKED";
  const freedomPlanEnabled = isTradingEnabled(kyc) && !isFreedomPlanBlocked;
  return freedomPlanEnabled;
};

export const getEnabledPlatformMotivators = (
  motivators = [],
  enabledFeatures = {}
) => {
  return motivators.filter((data) => validateFeature(data.id, enabledFeatures));
};

export const handleMarketingBanners = (
  data,
  sendEvents,
  navigate,
  openPageLoader
) => {
  const cardClick = data.eventStatus || data.id;
  sendEvents("next", {
    primaryCategory: "marketing banner carousel",
    cardClick,
  });
  if (data.actionUrl) {
    nativeCallback({
      action: "open_in_browser",
      message: {
        url: data.actionUrl,
      },
    });
    return;
  }
  const isStocksId = ["stocksBanner", "stocks"].includes(data.id);
  if (isStocksId) {
    openStocks(openPageLoader);
    return;
  }
  const config = getConfig();
  if (data.id === "ipoBanner") {
    if (isFunction(openPageLoader)) {
      openPageLoader();
    }
    window.location.href = `${config.base_url}/page/equity/tpp/${data.type}`;
    return;
  }
  const path = WEBAPP_LANDING_PATHNAME_MAPPER[data.id] || "/";
  if (data.id === 'nfoCheckout') {
    navigate(path, {
      searchParams: buildUrlWithQueryParams(
        config.searchParams,
        {
          isin: data.isin,
        }
      )
    });
    return;
  }
  navigate(path);
};

export const openStocks = (openPageLoader) => {
  const config = getConfig();
  if (config.Web) {
    if (isFunction(openPageLoader)) {
      openPageLoader();
    }
    window.location.href = `${config.base_url}/page/equity/launchapp`;
  } else {
    nativeCallback({
      action: "open_equity",
    });
  }
};

export const getContactVerification = (
  kyc,
  isAuthVerificationDisplayed,
  screenName
) => {
  let contactData = {};
  const contactDetails = kyc?.identification?.meta_data;
  if (!isEmpty(contactDetails)) {
    if (contactDetails.mobile_number_verified === false) {
      const contactValue = splitMobileNumberFromContryCode(
        contactDetails?.mobile_number
      );
      contactData = {
        communicationType: "mobile",
        contactValue,
        contactNotVerified: true,
      };
    }
  }
  if (!isAuthVerificationDisplayed && screenName === "LANDING") {
    if (!isEmpty(contactDetails)) {
      let contactType,
        contactValue,
        countryCode,
        isVerified = true;
      if (
        !isEmpty(contactDetails.mobile_number) &&
        !contactDetails.mobile_number_verified
      ) {
        const [code, number] = contactDetails?.mobile_number?.toString().split("|");
        contactType = "mobile";
        isVerified = false;
        contactValue = number;
        countryCode = code;
      } else if (
        !isEmpty(contactDetails.email) &&
        !contactDetails.email_verified
      ) {
        contactType = "email";
        contactValue = contactDetails.email;
        isVerified = false;
      }
      if (!isVerified) {
        return {
          ...contactData,
          ...AUTH_VERIFICATION_DATA[contactType],
          countryCode,
          contactType,
          contactValue,
          showAuthVerification: true,
        };
      }
    }
  }
  return contactData;
};

export const getKycBottomsheetData = (
  kycData = {},
  appStorage = {},
  code,
  productName
) => {
  const {
    isCompliant,
    kycJourneyStatus,
    tradingEnabled,
    isMfInvested,
    kyc,
    user,
  } = kycData;
  let premiumDialogData = {};
  let premiumOnboardingStatus = "";
  const isCompliantNonEquityFlow = isCompliant && !tradingEnabled;
  const showPremiumDialog =
    isCompliantNonEquityFlow && !appStorage.isPremiumBottomsheetDisplayed;
  if (showPremiumDialog) {
    premiumOnboardingStatus = kycJourneyStatus;
    const isKycStatusIncomplete = [
      "ground_premium",
      "init",
      "incomplete",
    ].includes(kycJourneyStatus);
    if (isKycStatusIncomplete) {
      premiumDialogData = premiumBottomSheetMapper[premiumOnboardingStatus];
      premiumDialogData.status = premiumOnboardingStatus;
    }

    const isKycSubmittedOrComplete = ["submitted", "complete"].includes(
      kycJourneyStatus
    );
    const isBankApproved = kyc.bank.meta_data_status === "approved";
    if (isKycSubmittedOrComplete && !isMfInvested && isBankApproved) {
      premiumDialogData = kycStatusMapper["mf_complete"];
      premiumDialogData.icon = `${productName}/${premiumDialogData.icon}`;
    }

    if (premiumOnboardingStatus && !isEmpty(premiumDialogData)) {
      if (code === "moneycontrol") {
        return {};
      } else {
        return {
          premiumDialogData,
          showPremiumOnboarding: true,
        };
      }
    }
  } else if (!appStorage.isKycBottomsheetDisplayed) {
    let modalData = {};
    const kycStatusesToShowDialog = [
      "verifying_trading_account",
      "complete",
      "fno_rejected",
      "esign_pending",
    ];
    if (kycStatusesToShowDialog.includes(kycJourneyStatus)) {
      const isKycCompleted = ["fno_rejected", "complete"].includes(
        kycJourneyStatus
      );
      if (isKycCompleted) {
        const dematAccountDisabled = isDematAccountDisabled(kyc);
        if (
          tradingEnabled &&
          kyc.equity_investment_ready &&
          user.equity_status === "init" &&
          kyc.equity_account_closure_status === "not_requested" &&
          !dematAccountDisabled
        ) {
          modalData = kycStatusMapper["kyc_verified"];
        } else if (!tradingEnabled && !isCompliant && !isMfInvested) {
          modalData = kycStatusMapper["mf_complete"];
        }
      } else {
        modalData = kycStatusMapper[kycJourneyStatus];
      }
    }

    if (!isEmpty(modalData)) {
      if (!modalData.dualButton) {
        modalData.oneButton = true;
      }
      return {
        showKycBottomsheet: true,
        modalData,
      };
    }
  }
  return {};
};

export function handleCampaignRedirection(url, showRedirectUrl) {
  const config = getConfig();
  let campLink = url;
  let plutusRedirectUrl = `${getBasePath()}/?is_secure=${
    config.isSdk
  }&partner_code=${config.code}`;
  campLink = `${campLink}${
    campLink.match(/[\?]/g) ? "&" : "?"
  }generic_callback=true&${
    showRedirectUrl ? "redirect_url" : "plutus_redirect_url"
  }=${encodeURIComponent(plutusRedirectUrl)}&campaign_version=1`;
  window.location.href = campLink;
}

export const handleCampaign =
  ({ campaignData = {}, handleLoader, handleBottomsheets, sendEvents, navigate }) =>
  () => {
    const isDormantCampaign = campaignData.campaign_name === CAMPAIGN_NAME_MAPPER.dormantRestriction
    if (isFunction(sendEvents)) {
      const intent = `${campaignData.title} ${isDormantCampaign ? `${EVENT_STATUS_MAPPER.home}` : ""}`;
      sendEvents("next", {
        intent,
      });
    }
    const campLink = campaignData.url;
    if (isEmpty(campLink)) {
      closeCampaignDialog({ campaignData, handleBottomsheets })();
      return;
    }
    if (isFunction(navigate) && isDormantCampaign) {
      handleBottomsheets({ [BOTTOMSHEET_KEYS.openCampaign]: false });
      navigate(KYC_PATHNAME_MAPPER.confirmDetails);
      return;
    }
    if (campaignData.campaign_name === "insurance_o2o_campaign") {
      hitCampaignFeedbackUrl(
        Api,
        campaignData.action_buttons?.buttons[0]?.feedback_url
      );
      return;
    }
    handleBottomsheets({ [BOTTOMSHEET_KEYS.openCampaign]: false });
    handleLoader({ skelton: true });
    const showRedirectUrl = campaignData.campaign_name === "whatsapp_consent";
    handleCampaignRedirection(campLink, showRedirectUrl);
  };

export const closeCampaignDialog =
  ({ campaignData, handleBottomsheets, sendEvents }) =>
  () => {
    const isDormantCampaign = campaignData.campaign_name === CAMPAIGN_NAME_MAPPER.dormantRestriction
    if (isFunction(sendEvents)) {
      const intent = `${campaignData.title} ${isDormantCampaign ? `${EVENT_STATUS_MAPPER.home}` : ""}`;
      sendEvents("back", {
        intent,
        outsideClick: true,
      });
    }
    const campaignsToHitFeedback = [
      "insurance_o2o_campaign",
      "trading_restriction_campaign",
    ];
    if (campaignsToHitFeedback.includes(campaignData.campaign_name)) {
      hitCampaignFeedbackUrl(
        Api,
        campaignData.action_buttons?.buttons[0]?.feedback_url
      );
    }
    handleBottomsheets({ [BOTTOMSHEET_KEYS.openCampaign]: false });
  };

export const getUserStatus = (kyc = {}, user = {}) => {
  const userKycReady = isReadyToInvest();
  const isMfInvested = user?.active_investment;
  const mfApplicationStatus = kyc?.application_status_v2 || "init";
  return {
    userKycReady,
    isMfInvested,
    mfApplicationStatus,
  };
};

export const getKycBottomsheetEventTitle = ({
  kyc,
  title,
  key,
}) => {
  const dematAccountDisabled = isDematAccountDisabled(kyc);
  const fromState = EVENT_STATUS_MAPPER[key] || "";
  const dematAccountRekycInprocess = isDematAccountRekycInprogress(kyc);
  if (dematAccountRekycInprocess) {
    title = "account activation pending";
  }
  title = `${title} ${dematAccountDisabled ? `${fromState}` : ""}`;
  return title;
};