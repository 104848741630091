import { get, isEmpty } from "lodash-es";
import { ACCOUNT_DORMANT_STATUS, ADDRESS_PROOF_OF_IDENTITY } from "../../constants/kyc";
import store from "../../dataLayer/store";
import { isReadyToInvest } from "../../kyc/services";

export const getPoaData = (value) => {
  return ADDRESS_PROOF_OF_IDENTITY.find((data) => data.value === value);
};

export const validateAddress = (value) => {
  const pattern = new RegExp(/^[a-zA-Z0-9\s,'-]*$/);
  const isValidAddress = pattern.test(value);
  return isValidAddress;
};

export const getAddressDetailsData = (kyc = {}, group = "address") => {
  const addressMetaData = kyc[group]?.meta_data || {};
  const city = addressMetaData.city || "";
  const address = addressMetaData.addressline || "";
  const state = addressMetaData.state || "";
  const pincode = addressMetaData.pincode || "";
  const docType = addressMetaData.doc_type || "";
  const country = addressMetaData?.country || "INDIA";
  const data = {
    city,
    address,
    state,
    pincode,
    docType,
    country,
  };
  return data;
};

export const getConfirmDetailsData = (kyc = {}) => {
  const addressData = getAddressDetailsData(kyc);
  const panData = kyc?.pan?.meta_data || {};
  const dob = panData.dob || "";
  const fatherName = panData.father_name || "";
  const data = {
    ...addressData,
    dob,
    fatherName,
  };
  return data;
};

export const getUserStatus = (kyc = {}, user = {}) => {
  const isMfKycCompleted = isReadyToInvest();
  const isMfInvested = user?.active_investment;
  const mfApplicationStatus = kyc?.application_status_v2;
  return {
    isMfKycCompleted,
    isMfInvested,
    mfApplicationStatus,
  };
};

export const gotoCompleteKyc = (navigate) => {
  navigate("/kyc/complete-kyc");
};

export const getReKycFormData = (kyc = {}) => {
  const addressData = getAddressDetailsData(kyc);
  const panData = get(kyc, "pan.meta_data", {});
  const equityIdentification = get(kyc, "equity_identification.meta_data", {});
  const email = equityIdentification.email || "";
  let mobile = equityIdentification.mobile_number || "";
  mobile = mobile.replace("|", " ");
  const name = panData.name || "";
  const data = {
    ...addressData,
    email,
    mobile,
    name,
  };
  return data;
};

export const isDematAccountDisabled = (kyc = {}) => {
  if (isEmpty(kyc)) {
    const state = store.getState();
    kyc = get(state, "app.kyc", {});
  }
  const dormancyStatus = get(
    kyc,
    "equity_account_dormant_status",
    ""
  );
  const isDemantAccountActivated = !dormancyStatus || isDematAccountStatusReactivated(dormancyStatus);
  return !isDemantAccountActivated;
};

export const isDematAccountStatusReactivated = (status) => {
  return status === ACCOUNT_DORMANT_STATUS.reactivated;
};

export const isDematAccountStatusFailed = (status) => {
  return status === ACCOUNT_DORMANT_STATUS.failed;
};

export const isDematAccountRekycInprogress = (kyc = {}) => {
  if (isEmpty(kyc)) {
    const state = store.getState();
    kyc = get(state, "app.kyc", {});
  }
  const dormancyStatus = get(
    kyc,
    "equity_account_dormant_status",
    ""
  );
  const dematAccountRekycInprocess = [ACCOUNT_DORMANT_STATUS.reKycInprocess, ACCOUNT_DORMANT_STATUS.failed].includes(dormancyStatus);
  return dematAccountRekycInprocess;
};